import React from 'react'

import PropTypes from 'prop-types'

import './feature-card4.css'

const FeatureCard4 = (props) => {
  return (
    <div className={`feature-card4-feature-card ${props.rootClassName} `}>
      <svg viewBox="0 0 1026.2674285714286 1024" className="feature-card4-icon">
        <path
          d="M477.714 668l-8.571 210.286-1.143 12.571-240-16.571c-29.714-2.286-54.286-30.286-65.143-55.429-22.857-53.143 6.857-116 24-166.857 0 0 44 6.857 290.857 16zM256.571 333.143l102.857 216.571-84-52.571c-128.571 146.857-140.571 256-140.571 256l-108.571-204c-22.286-33.143-2.286-69.143-2.286-69.143s20-36 65.143-107.429l-80-49.143zM960 628.571l-107.429 205.143c-14.857 37.143-56 40.571-56 40.571s-40.571 4-125.143 6.857l4.571 93.714-131.429-209.714 120.571-206.857 4 98.857c193.714 23.429 290.857-28.571 290.857-28.571zM511.429 100.571c0 0-26.857 35.429-151.429 248.571l-181.143-106.857-10.857-6.857 128.571-203.429c16-25.143 52-34.286 80-31.429 57.143 5.143 98.286 60.571 134.857 100zM885.714 276l121.143 207.429c15.429 25.714 6.286 61.714-8.571 85.714-30.857 48-99.429 59.429-150.857 73.714 0 0-19.429-40.571-151.429-249.143l178.857-111.429zM804 146.857l81.143-47.429-125.714 213.143-239.429-11.429 86.286-49.143c-68.571-182.286-159.429-245.143-159.429-245.143l231.429 0.571c40-3.429 61.714 30.857 61.714 30.857s22.286 34.857 64 108.571z"
          className=""
        ></path>
      </svg>
      <h2 className="feature-card4-text">{props.new_prop}</h2>
      <span className="feature-card4-text1">{props.description}</span>
    </div>
  )
}

FeatureCard4.defaultProps = {
  rootClassName: '',
  new_prop: 'Lorem ipsum',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
}

FeatureCard4.propTypes = {
  rootClassName: PropTypes.string,
  new_prop: PropTypes.string,
  description: PropTypes.string,
}

export default FeatureCard4
