import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import TestimonialCard11 from '../components/testimonial-card11'
import TestimonialCard12 from '../components/testimonial-card12'
import './home.css'

const Home = (props) => {
  const burgerPagePrefix = 'home'

  useEffect(() => {
    const burgerBtn = document.getElementsByClassName(burgerPagePrefix + '-burger-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (burgerBtn && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.add("teleport-show");
      }
      burgerBtn.addEventListener("click", handleClick);
      return () => {
        burgerBtn.removeEventListener('click', handleClick)
      }
    }
  }, []);

  useEffect(() => {
    const mobileCloseMenu = document.getElementsByClassName(burgerPagePrefix + '-close-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (mobileCloseMenu && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.remove("teleport-show");
      }
      mobileCloseMenu.addEventListener("click", handleClick);
      return () => {
        mobileCloseMenu.removeEventListener('click', handleClick)
      }
    }
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>Nexus at Cornell</title>
        <meta
          name="description"
          content="Student project team building an autonomous robot to help filter microplastics on the beaches."
        />
        <meta property="og:title" content="Nexus at Cornell" />
        <meta
          property="og:description"
          content="Student project team building an autonomous robot to help filter microplastics on the beaches."
        />
      </Helmet>
      <div data-role="Header" className="home-navbar-container">
        <div className="home-navbar">
          <div className="home-left-side">
            <img
              alt="image"
              src="/playground_assets/logo1-1500h.png"
              className="home-image"
            />
            <div className="home-burger-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="home-links-container">
              <Link to="/" className="home-link Anchor">
                Home
              </Link>
              <Link to="/about-us" className="home-link01 Anchor">
                About Us
              </Link>
              <Link to="/team" className="home-link02 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="home-link03 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="home-link04 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
          <div className="home-right-side">
            <Link to="/support-us" className="home-cta-btn Anchor button">
              Support Us
            </Link>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-container1">
              <img
                alt="image"
                src="/playground_assets/logo1-1500h.png"
                className="home-image1"
              />
              <div data-role="CloseMobileMenu" className="home-close-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="home-links-container1">
              <Link to="/" className="home-link05 Anchor">
                Home
              </Link>
              <Link to="/about-us" className="home-link06 Anchor">
                About Us
              </Link>
              <Link to="/team" className="home-link07 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="home-link08 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="home-link09 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner">
      </div>
      <div className="home-testimonial">
        <div className="home-container2">
          <h1 className="home-text">Our Mission Statement</h1>
          <br></br>
          <TestimonialCard12
            quote="Nexus is a student-run project team on campus that aims to create novel technologies to be deployed by global organizations for social impact. Currently, we are building a solar-powered robot that filters microplastics while autonomously traversing beaches, picking up what cleanup volunteers can’t.
            The amount of plastic pollution has been rising readily. Nexus’ goal is to be a significant actor and leader in the fight against such environmental pollution.\n\n
            Starting off with just 6 original members, Nexus grew to become a team of 30+ talented and passionate individuals who want to help make the world a better place. We hope to increase our enthusiasm for people, product, and innovation through a collaborative environment within our team.\n\n
            Our team has been working hard to make our vision a reality for global impact through the interdisciplinary collaboration between mechanical, electrical, and software engineers, as well as the business development and outreach team. Most importantly, we hope to increase research and awareness around the intersection of technology and environmental sustainability to do our part in saving our planet. As our team expands, we plan to tackle global issues and engineering challenges to create a positive social impact on our planet."
            picture_src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
          ></TestimonialCard12>
        </div>
      </div>
      <div className="home-testimonial">
        <div className="home-container2">
          <h1 className="home-text">Our Story</h1>
          <br></br>
          <div className="home-container3">
            <TestimonialCard11
              quote="According to a study in 2014, an estimated 15-51 trillion microplastic particles float on the ocean surface."
              picture_src="https://images.unsplash.com/photo-1557053910-d9eadeed1c58?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="testimonial-card11-root-class-name2"
            ></TestimonialCard11>
            <TestimonialCard11
              quote="However, that is just 1 percent of the plastic waste that enters our ocean. "
              rootClassName="testimonial-card11-root-class-name"
            ></TestimonialCard11>
            <TestimonialCard11
              quote="These microplastics are plastic debris of less than 5 mm in length which make them a problem that is easy to ignore but difficult to clean up."
              picture_src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="testimonial-card11-root-class-name1"
            ></TestimonialCard11>
          </div>
        </div>
      </div>
      <div className="home-section-separator"></div>
      <div className="home-section-separator1"></div>
      <div className="home-section-separator2"></div>
      <div className="home-footer-container">
        <div className="home-footer">
          <div className="home-social-links">
            <a
              href="mailto:cornellnexus@gmail.com?subject="
              className="home-link10"
            >
              <svg viewBox="0 0 1024 1024" className="home-icon04">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornellnexus/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link12"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon08">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
          </div>
          <div className="disclaimer-footer">
            <span id="footer-center" className="home-heading">Nexus at Cornell, 2024</span>
            <span className="disclaimer">This organization is a registered student organization of Cornell University</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
