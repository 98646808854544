import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Recruitment from './views/recruitment'
import AboutUs from './views/about-us'
import Team from './views/team'
import Home from './views/home'
import Subteams from './views/subteams'
import SupportUs from './views/support-us'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Recruitment} exact path="/recruitment" />
        <Route component={AboutUs} exact path="/about-us" />
        <Route component={Team} exact path="/team" />
        <Route component={Home} exact path="/" />
        <Route component={Subteams} exact path="/subteams" />
        <Route component={SupportUs} exact path="/support-us" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
