import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import BlogPostCard3 from '../components/blog-post-card3'
import BlogPostCard31 from '../components/blog-post-card31'
import './subteams.css'

const Subteams = (props) => {
  const burgerPagePrefix = 'subteams'

  useEffect(() => {
    const burgerBtn = document.getElementsByClassName(burgerPagePrefix + '-burger-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (burgerBtn && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.add("teleport-show");
      }
      burgerBtn.addEventListener("click", handleClick);
      return () => {
        burgerBtn.removeEventListener('click', handleClick)
      }
    }
  }, []);

  useEffect(() => {
    const mobileCloseMenu = document.getElementsByClassName(burgerPagePrefix + '-close-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (mobileCloseMenu && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.remove("teleport-show");
      }
      mobileCloseMenu.addEventListener("click", handleClick);
      return () => {
        mobileCloseMenu.removeEventListener('click', handleClick)
      }
    }
  }, []);

  return (
    <div className="subteams-container">
      <Helmet>
        <title>Subteams - Nexus at Cornell</title>
        <meta
          name="description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
        <meta property="og:title" content="Subteams - Nexus at Cornell" />
        <meta
          property="og:description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
      </Helmet>
      <div data-role="Header" className="subteams-navbar-container">
        <div className="subteams-navbar">
          <div className="subteams-left-side">
            <img
              alt="image"
              src="/playground_assets/logo1-1500h.png"
              className="subteams-image"
            />
            <div className="subteams-burger-menu">
              <svg viewBox="0 0 1024 1024" className="subteams-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="subteams-links-container">
              <Link to="/" className="subteams-link Anchor">
                Home
              </Link>
              <Link to="/about-us" className="subteams-link01 Anchor">
                About Us
              </Link>
              <Link to="/team" className="subteams-link02 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="subteams-link03 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="subteams-link04 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
          <div className="subteams-right-side">
            <Link to="/support-us" className="subteams-cta-btn Anchor button">
              Support Us
            </Link>
          </div>
          <div data-role="MobileMenu" className="subteams-mobile-menu">
            <div className="subteams-container1">
              <img
                alt="image"
                src="/playground_assets/logo1-1500h.png"
                className="subteams-image1"
              />
              <div data-role="CloseMobileMenu" className="subteams-close-menu">
                <svg viewBox="0 0 1024 1024" className="subteams-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="subteams-links-container1">
              <Link to="/" className="subteams-link05 Anchor">
                Home
              </Link>
              <Link to="/about-us" className="subteams-link06 Anchor">
                About Us
              </Link>
              <Link to="/team" className="subteams-link07 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="subteams-link08 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="subteams-link09 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="inspiration" className="subteams-features">
        <div className="subteams-banner">
          <h1 className="subteams-text">Subteams</h1>
          <span className="subteams-text1">
            <span>
              <span>
                Our team is broken up into 4 main subteams: electrical,
                software, mechanical, and business.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Each team may be working on their own individual tasks, whether
                that be working with sensors or building a pitch deck. However,
                the integration between these four teams is what allows us to
                work on the robot efficiently and allows members to learn about
                disciplines outside of their subteam.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="subteams-blog">
          <div className="subteams-container2">
            <BlogPostCard3
              title="Electrical Team"
              new_prop="Electrical Team"
              image_src="/playground_assets/electrical2-1500w.jpg"
              description="The electrical subteam is in charge of creating a reliable sensor network to collect relevant data for real time analysis, generating an appropriate power system to drive the motors and all of the on-board electronics, and collaborating with other subteams to integrate various aspects of the robot. Currently some of our main tasks include working to develop a custom PCB for our sensors to easily communicate with the Raspberry Pi and any other peripherals, building and integrating a battery pack, and helping Software test their Kalman Filter implementation."
              rootClassName="blog-post-card3-root-class-name1"
            ></BlogPostCard3>
          </div>
          <div className="subteams-container3">
            <BlogPostCard31
              title="Software Team"
              new_prop="Software Team"
              image_src="/playground_assets/soft-1500w.jpg"
              description="The software subteam is the brain behind the robot. Software is working on a wide range of different challenges. Our role provides everyone on the team the opportunity to take what you learn in the classroom (e.g. all the theory and structural foundation) one step further as we work together to make a fully functional, intelligent robot to traverse beaches efficiently. Some examples of our current tasks are reading sensor data to detect our robot’s location and dynamically adjust its pathing, communicating with the robot using a graphical user interface (GUI), and visualizing our robot’s data through simulated animations."
              rootClassName="blog-post-card31-root-class-name"
            ></BlogPostCard31>
          </div>
          <div className="subteams-container4">
            <BlogPostCard3
              title="Mechanical Team"
              new_prop="Mechanical Team"
              image_src="/playground_assets/mech-team.jpeg"
              description="  The mechanical team is currently in the manufacturing stage of building our beach-cleaning robot. We are working on the drivetrain and filtration assemblies and using 3D modeling software such as SolidWorks to model our robot. We are a small and personal team - there are opportunities to work on many different components of the robot and to tackle and solve new problems creatively and obtain hands-on experience"
              rootClassName="blog-post-card3-root-class-name"
            ></BlogPostCard3>
          </div>
          <div className="subteams-container5">
            <BlogPostCard31
              title="Business Team"
              new_prop="Business Team"
              image_alt="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              image_src="/playground_assets/bsn-team.jpeg"
              description=" The business subteam team goes beyond finance and general business operations in order to promote our team mission both locally and globally.  Aspects of business include social media, design, marketing, fundraising, and community outreach. Right now our primary focuses are raising money to help support our other subteams and to increase overall presence on and off of campus. As a small team, we have the opportunity to be well rounded and gain valuable experience that can help accelerate career growth."
              rootClassName="blog-post-card31-root-class-name1"
            ></BlogPostCard31>
          </div>
        </div>
        <div className="subteams-heading-container"></div>
      </div>
      <div className="subteams-section-separator"></div>
      <div className="subteams-section-separator1"></div>
      <div className="subteams-section-separator2"></div>
      <div className="subteams-section-separator3"></div>
      <div className="subteams-footer-container">
        <div className="subteams-footer">
          <div className="subteams-social-links">
            <a
              href="mailto:cornellnexus@gmail.com?subject="
              className="subteams-link10"
            >
              <svg viewBox="0 0 1024 1024" className="subteams-icon04">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornellnexus/"
              target="_blank"
              rel="noreferrer noopener"
              className="subteams-link12"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="subteams-icon08"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
          </div>
          <div className="disclaimer-footer">
            <span id="footer-center" className="subteams-heading">Nexus at Cornell, 2024</span>
            <span className="disclaimer">This organization is a registered student organization of Cornell University</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subteams
