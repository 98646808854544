import React from 'react'

import PropTypes from 'prop-types'

import './testimonial-card12.css'

const Testimonialcard12 = (props) => {

  const lines = props.quote.split('\\n');
  const renderedLines = lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <div
      className={`testimonial-card12-testimonial-card ${props.rootClassName} `}
    >
      <div className="testimonial-card12-testimonial">
        <span className="testimonial-card12-text">{renderedLines}</span>
      </div>
    </div>
  )
}

Testimonialcard12.defaultProps = {
  rootClassName: '',
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
}

Testimonialcard12.propTypes = {
  rootClassName: PropTypes.string,
  quote: PropTypes.string,
}

export default Testimonialcard12
