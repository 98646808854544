import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import TestimonialCard3 from '../components/testimonial-card3'
import './recruitment.css'

const Recruitment = (props) => {
  const burgerPagePrefix = 'recruitment'

  useEffect(() => {
    const burgerBtn = document.getElementsByClassName(burgerPagePrefix + '-burger-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (burgerBtn && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.add("teleport-show");
      }
      burgerBtn.addEventListener("click", handleClick);
      return () => {
        burgerBtn.removeEventListener('click', handleClick)
      }
    }
  }, []);

  useEffect(() => {
    const mobileCloseMenu = document.getElementsByClassName(burgerPagePrefix + '-close-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (mobileCloseMenu && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.remove("teleport-show");
      }
      mobileCloseMenu.addEventListener("click", handleClick);
      return () => {
        mobileCloseMenu.removeEventListener('click', handleClick)
      }
    }
  }, []);

  return (
    <div className="recruitment-container">
      <Helmet>
        <title>Recruitment - Nexus at Cornell</title>
        <meta
          name="description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
        <meta property="og:title" content="Recruitment - Nexus at Cornell" />
        <meta
          property="og:description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
      </Helmet>
      <div data-role="Header" className="recruitment-navbar-container">
        <div className="recruitment-navbar">
          <div className="recruitment-left-side">
            <img
              alt="image"
              src="/playground_assets/logo1-1500h.png"
              className="recruitment-image"
            />
            <div className="recruitment-burger-menu">
              <svg viewBox="0 0 1024 1024" className="recruitment-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="recruitment-links-container">
              <Link to="/" className="recruitment-link Anchor">
                Home
              </Link>
              <Link to="/about-us" className="recruitment-link01 Anchor">
                About Us
              </Link>
              <Link to="/team" className="recruitment-link02 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="recruitment-link03 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="recruitment-link04 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
          <div className="recruitment-right-side">
            <Link
              to="/support-us"
              className="recruitment-cta-btn Anchor button"
            >
              Support Us
            </Link>
          </div>
          <div data-role="MobileMenu" className="recruitment-mobile-menu">
            <div className="recruitment-container1">
              <img
                alt="image"
                src="/playground_assets/logo1-1500h.png"
                className="recruitment-image1"
              />
              <div
                data-role="CloseMobileMenu"
                className="recruitment-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="recruitment-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="recruitment-links-container1">
              <Link to="/" className="recruitment-link05 Anchor">
                Home
              </Link>
              <Link to="/about-us" className="recruitment-link06 Anchor">
                About Us
              </Link>
              <Link to="/team" className="recruitment-link07 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="recruitment-link08 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="recruitment-link09 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="recruitment-steps">
        <h1 className="recruitment-text">
          Fall 2024 Recruitment Opening 8/19
        </h1>
        <img
          alt="image"
          src="/playground_assets/FA24-recruitment.png"
          class="recruitment-timeline-image"
        />
        <div className="recruitment-testimonial">
          <div className="recruitment-container2">
            <TestimonialCard3
              name="<b>Electrical Team</b>: Upperclassmen, Freshmen, and Transfers\n
            <b>Software Team</b>: Upperclassmen, Freshmen, and Transfers\n
            <b>Business Team</b>: Upperclassmen, Freshmen, and Transfers\n
            <b>Mechanical Team</b>: Upperclassmen, Freshmen, and Transfers\n
            "
              quote="Who Are We Looking For"
              picture_src="https://images.unsplash.com/photo-1583195764036-6dc248ac07d9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="testimonial-card3-root-class-name"
            ></TestimonialCard3>
            <TestimonialCard3
              name="
            <a href='https://docs.google.com/forms/d/1aZWUe8IRotDey20-rHGgxtJEfxGTkEbdK1U9GaEinuE/edit'><b>Nexus Application Link (Freshmen/Transfers)</b></a>\n
            <a href='https://docs.google.com/forms/d/1Xt_Bki7JGAvic-S3Xw9NDhK-dP8KgbdWhbkx5PN8iSw/edit'><b>Nexus Application Link (Upperclassmen)</b></a>\n
            <a href='https://cornell.ca1.qualtrics.com/jfe/form/SV_3dF2fjywuzGG1wi'><b>General Project Team Application Link</b></a>\n
            <a href='https://www.engineering.cornell.edu/cornell-engineering-student-project-teams-join-a-team'><b>Fall Recruitment Timeline</b></a>\n
            ‎"
              quote="Application Links"
              picture_src="https://images.unsplash.com/photo-1583195764036-6dc248ac07d9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
              rootClassName="testimonial-card3-root-class-name"
            ></TestimonialCard3>
          </div>
        </div>
      </div>

      <div className="recruitment-testimonial">
        <h1 className="recruitment-text1">
          <span>FAQ&apos;s</span>
        </h1>

        <div className="recruitment-container2">
          <TestimonialCard3
            name="Applications open in the Fall (August) and Spring (January) and we will be recruiting for subteams that still need to fill their teams. The application consists of a short essay and a resume drop. After applying, all you have to do is wait for next steps."
            quote="How Can I Apply?"
            picture_src="https://images.unsplash.com/photo-1583195764036-6dc248ac07d9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="testimonial-card3-root-class-name"
          ></TestimonialCard3>
          <TestimonialCard3
            name="Before recruitment, we always hold an information session as well as host a booth at club fest. Attending these events, as well as emailing us or sending us a DM on IG are great ways to show interest in our team."
            quote="How Can I Show Interest?"
            picture_src="https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="testimonial-card3-root-class-name"
          ></TestimonialCard3>
          <TestimonialCard3
            name=" Yes, many members on our business team are non-engineering majors. We also encourage all majors to apply for our technical teams as well. "
            quote="Can Non-Engineering Majors Apply?"
            picture_src="https://images.unsplash.com/photo-1546456073-ea246a7bd25f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fGJsYWNrJTIwbWFufGVufDB8fHx8MTYyNjQ1MjAwOA&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="testimonial-card3-root-class-name"
          ></TestimonialCard3>
        </div>
        <span className="recruitment-text3">
          <span></span>
        </span>
      </div>
      <div className="recruitment-section-separator"></div>
      <div className="recruitment-section-separator1"></div>
      <div className="recruitment-section-separator2"></div>
      <div className="recruitment-section-separator3"></div>
      <div className="recruitment-footer-container">
        <div className="recruitment-footer">
          <div className="recruitment-social-links">
            <a
              href="mailto:cornellnexus@gmail.com?subject="
              className="recruitment-link10"
            >
              <svg viewBox="0 0 1024 1024" className="recruitment-icon04">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornellnexus/"
              target="_blank"
              rel="noreferrer noopener"
              className="recruitment-link12"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="recruitment-icon08"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
          </div>
          <div className="disclaimer-footer">
            <span id="footer-center" className="recruitment-heading">Nexus at Cornell, 2024</span>
            <span className="disclaimer">This organization is a registered student organization of Cornell University</span>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Recruitment
