import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard1 from '../components/feature-card1'
import './team.css'

const Team = (props) => {
  const burgerPagePrefix = 'team'

  useEffect(() => {
    const burgerBtn = document.getElementsByClassName(burgerPagePrefix + '-burger-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (burgerBtn && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.add("teleport-show");
      }
      burgerBtn.addEventListener("click", handleClick);
      return () => {
        burgerBtn.removeEventListener('click', handleClick)
      }
    }
  }, []);

  useEffect(() => {
    const mobileCloseMenu = document.getElementsByClassName(burgerPagePrefix + '-close-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (mobileCloseMenu && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.remove("teleport-show");
      }
      mobileCloseMenu.addEventListener("click", handleClick);
      return () => {
        mobileCloseMenu.removeEventListener('click', handleClick)
      }
    }
  }, []);

  return (
    <div className="team-container">
      <Helmet>
        <title>Team - Nexus at Cornell</title>
        <meta
          name="description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
        <meta property="og:title" content="Team - Nexus at Cornell" />
        <meta
          property="og:description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
      </Helmet>
      <div data-role="Header" className="team-navbar-container">
        <div className="team-navbar">
          <div className="team-left-side">
            <img
              alt="image"
              src="/playground_assets/logo1-1500h.png"
              className="team-image"
            />
            <div className="team-burger-menu">
              <svg viewBox="0 0 1024 1024" className="team-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="team-links-container">
              <Link to="/" className="team-link Anchor">
                Home
              </Link>
              <Link to="/about-us" className="team-link01 Anchor">
                About Us
              </Link>
              <Link to="/team" className="team-link02 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="team-link03 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="team-link04 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
          <div className="team-right-side">
            <Link to="/support-us" className="team-cta-btn Anchor button">
              Support Us
            </Link>
          </div>
          <div data-role="MobileMenu" className="team-mobile-menu">
            <div className="team-container1">
              <img
                alt="image"
                src="/playground_assets/logo1-1500h.png"
                className="team-image1"
              />
              <div data-role="CloseMobileMenu" className="team-close-menu">
                <svg viewBox="0 0 1024 1024" className="team-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="team-links-container1">
              <Link to="/" className="team-link05 Anchor">
                Home
              </Link>
              <Link to="/about-us" className="team-link06 Anchor">
                About Us
              </Link>
              <Link to="/team" className="team-link07 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="team-link08 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="team-link09 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="inspiration" className="team-features">
        <div className="team-banner">
          <h1 className="team-text">Meet Our Team</h1>
        </div>
        <div className="team-features1">
          <h1 className="team-title">
            <span>Electrical Team</span>
          </h1>
          <div className="team-container2">
            <div className="team-container3">
              <FeatureCard1
                title="Amber Li"
                title1="Electrical Lead - 2026"
                new_prop="Amber Li"
                image_src="/playground_assets/adl94.jpeg"
                rootClassName="feature-card1-root-class-name7"
              ></FeatureCard1>
              <FeatureCard1
                title="George Maidhof"
                title1="Class of 2025"
                new_prop="George Maidhof"
                image_src="/playground_assets/gpm58.jpeg"
                rootClassName="feature-card1-root-class-name"
              ></FeatureCard1>
              <FeatureCard1
                title="Nicholas Papapanou"
                title1="Class of 2025"
                new_prop="Nick Papapanou"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/np.jpeg"
                rootClassName="feature-card1-root-class-name3"
              ></FeatureCard1>
              <FeatureCard1
                title="Casey Kashishian"
                title1="Class of 2026"
                new_prop="Casey Kashishian"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/ck.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
              <FeatureCard1
                title="Andrew Estes"
                title1="Class of 2026"
                new_prop="Andrew Estes"
                image_src="/playground_assets/img_1417-300h.jpg"
                rootClassName="feature-card1-root-class-name7"
              ></FeatureCard1>
              <FeatureCard1
              title="Jasmine Si"
              title1="Class of 2026"
              new_prop="Jasmine Si"
              image_src="/playground_assets/js.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
                title="Aaron Sucov"
                title1="Class of 2027"
                new_prop="Aaron Sucov"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/as.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
            <FeatureCard1
                title="Antranig Baghdassarian"
                title1="Class of 2027"
                new_prop="Antranig Baghdassarian"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/ab2864.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
              <FeatureCard1
                title="Parishee Bajaj"
                title1="Class of 2027"
                new_prop="Parishee Bajaj"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/pb587.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
              <FeatureCard1
                title="Akmal Rupasingha"
                title1="Class of 2027"
                new_prop="Akmal Rupasingha"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/ar2346.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
              <FeatureCard1
                title="Anamitra Rawat"
                title1="Class of 2027"
                new_prop="Anamitra Rawat"
                image_alt="/playground_assets/andreamiramontes..png"
                image_src="/playground_assets/ar2342.jpeg"
                rootClassName="feature-card1-root-class-name8"
              ></FeatureCard1>
            </div>
          </div>
        </div>
        <div className="team-features2">
          <h1 className="team-title">Software Team</h1>
          <div className="team-container4">
            <FeatureCard1
              title="Lin Jin"
              title1="Full Team Lead - 2025"
              new_prop="Lin Jin"
              image_src="/playground_assets/lj233.jpeg"
              rootClassName="feature-card1-root-class-name6"
            ></FeatureCard1>
            <FeatureCard1
              title="Vinny Huang"
              title1="Software Lead - 2026"
              new_prop="Vinny Huang"
              image_src="/playground_assets/vh.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Shreya Kumar"
              title1="Class of 2024"
              new_prop="Shreya Kumar"
              image_src="/playground_assets/sk-300h.jpg"
              rootClassName="feature-card1-root-class-name21"
            ></FeatureCard1>
            <FeatureCard1
              title="Allen Chen"
              title1="Class of 2025"
              new_prop="Allen Chen"
              image_src="/playground_assets/ac-200w.jpg"
              rootClassName="feature-card1-root-class-name14"
            ></FeatureCard1>
            <FeatureCard1
              title="Gary Chen"
              title1="Class of 2025"
              new_prop="Gary Chen"
              image_src="/playground_assets/gc558.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Andrew Lowitt"
              title1="Class of 2026"
              new_prop="Andrew Lowitt"
              image_src="/playground_assets/al.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Sophia Pan"
              title1="Class of 2026"
              new_prop="Sophia Pan"
              image_src="/playground_assets/sp.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Luke Tao"
              title1="Class of 2026"
              new_prop="Luke Tao"
              image_src="/playground_assets/lyt5.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Grace Wei"
              title1="Class of 2026"
              new_prop="Grace Wei"
              image_src="/playground_assets/gtw25.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Michael Wu"
              title1="Class of 2026"
              new_prop="Michael Wu"
              image_src="/playground_assets/mw885.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Natalie Leung"
              title1="Class of 2027"
              new_prop="Natalie Leung"
              image_src="/playground_assets/nl.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Ahan Mishra"
              title1="Class of 2027"
              new_prop="Ahan Mishra"
              image_src="/playground_assets/am.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
            <FeatureCard1
              title="Amishi Gupta"
              title1="Class of 2027"
              new_prop="Amishi Gupta"
              image_src="/playground_assets/ag2424.jpeg"
              rootClassName="feature-card1-root-class-name5"
            ></FeatureCard1>
          </div>
        </div>
        <div className="team-features3">
          <h1 className="team-title">Mechanical Team</h1>
          <div className="team-container5">
            <FeatureCard1
              title="Chris Bauer"
              title1="Mechanical Lead - 2025"
              new_prop="Chris Bauer"
              image_alt="Mechanical Team"
              image_src="/playground_assets/cb-300h.jpg"
              rootClassName="feature-card1-root-class-name27"
            ></FeatureCard1>
            <FeatureCard1
              title="Rose Basch"
              title1="Class of 2025"
              new_prop="Rose Basch"
              image_alt="Mechanical Team"
              image_src="/playground_assets/rb-300h.jpg"
              rootClassName="feature-card1-root-class-name19"
            ></FeatureCard1>
            <FeatureCard1
              title="Katlynn Ryu"
              title1="Class of 2026"
              new_prop="Katlynn Ryu"
              image_alt="Mechanical Team"
              image_src="/playground_assets/kmr238.jpeg"
              rootClassName="feature-card1-root-class-name12"
            ></FeatureCard1>
            <FeatureCard1
              title="Lucca Correia"
              title1="Class of 2026"
              new_prop="Lucca Correia"
              image_alt="Mechanical Team"
              image_src="/playground_assets/img_1443-300h.jpg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="Yujean Choi"
              title1="Class of 2026"
              new_prop="Yujean Choi"
              image_src="/playground_assets/yc.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Mia Tarantini"
              title1="Class of 2026"
              new_prop="Mia Tarantini"
              image_alt="Mechanical Team"
              image_src="/playground_assets/mt.jpeg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="Ethan Sekiguchi"
              title1="Class of 2026"
              new_prop="Ethan Sekiguchi"
              image_alt="Mechanical Team"
              image_src="/playground_assets/eks84.jpeg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="Cleo Hamilton"
              title1="Class of 2026"
              new_prop="Cleo Hamilton"
              image_alt="Mechanical Team"
              image_src="/playground_assets/guest.png"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="Farah Achouri"
              title1="Class of 2027"
              new_prop="Farah Achouri"
              image_alt="Mechanical Team"
              image_src="/playground_assets/fa.jpeg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="Natalie Kaplan"
              title1="Class of 2027"
              new_prop="Natalie Kaplan"
              image_alt="Mechanical Team"
              image_src="/playground_assets/njk82.jpeg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
            <FeatureCard1
              title="William Dignam"
              title1="Class of 2027"
              new_prop="William Dignam"
              image_alt="Mechanical Team"
              image_src="/playground_assets/wed45.jpeg"
              rootClassName="feature-card1-root-class-name11"
            ></FeatureCard1>
          </div>
        </div>
        <div className="team-features4">
          <h1 className="team-title">Business Team</h1>
          <div className="team-container6">
            <FeatureCard1
              title="Mona Wu"
              title1="Full Team Lead - 2025"
              new_prop="Mona Wu"
              image_src="/playground_assets/mw.jpeg"
              rootClassName="feature-card1-root-class-name30"
            ></FeatureCard1>
            <FeatureCard1
              title="Tanay Nisar"
              title1="Business Lead - 2026"
              new_prop="Tanay Nisar"
              image_src="/playground_assets/tn.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Elaine Wu"
              title1="Class of 2026"
              new_prop="Elaine Wu"
              image_src="/playground_assets/ew.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Vedaant Shah"
              title1="Class of 2026"
              new_prop="Vedaant Shah"
              image_src="/playground_assets/vs.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Jeana Han"
              title1="Class of 2026"
              new_prop="Jeana Han"
              image_src="/playground_assets/jh.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Oscar Lam"
              title1="Class of 2026"
              new_prop="Oscar Lam"
              image_src="/playground_assets/ol56.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
            <FeatureCard1
              title="Neha Jain"
              title1="Class of 2027"
              new_prop="Neha Jain"
              image_src="/playground_assets/nj253.jpeg"
              rootClassName="feature-card1-root-class-name29"
            ></FeatureCard1>
          </div>
        </div>
      </div>
      <div className="team-section-separator"></div>
      <div className="team-section-separator1"></div>
      <div className="team-section-separator2"></div>
      <div className="team-section-separator3"></div>
      <div className="team-footer-container">
        <div className="team-footer">
          <div className="team-social-links">
            <a
              href="mailto:cornellnexus@gmail.com?subject="
              className="team-link10"
            >
              <svg viewBox="0 0 1024 1024" className="team-icon04">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornellnexus/"
              target="_blank"
              rel="noreferrer noopener"
              className="team-link12"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="team-icon08">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
          </div>
          <div className="disclaimer-footer">
            <span id="footer-center" className="team-heading">Nexus at Cornell, 2024</span>
            <span className="disclaimer">This organization is a registered student organization of Cornell University</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
