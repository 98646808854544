import React from 'react'

import PropTypes from 'prop-types'

import './feature-card1.css'

const FeatureCard1 = (props) => {
  return (
    <div className={`feature-card1-feature-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="feature-card1-image"
      />
      <h3 className="feature-card1-text">{props.new_prop}</h3>
      <h2 className="feature-card1-text1">{props.title1}</h2>
    </div>
  )
}

FeatureCard1.defaultProps = {
  image_alt: 'image',
  title1: 'Lorem ipsum',
  rootClassName: '',
  new_prop: 'Lorem ipsum',
  image_src: 'dc63014a-7eb8-4756-95d6-7d7904d43436',
}

FeatureCard1.propTypes = {
  image_alt: PropTypes.string,
  title1: PropTypes.string,
  rootClassName: PropTypes.string,
  new_prop: PropTypes.string,
  image_src: PropTypes.string,
}

export default FeatureCard1
