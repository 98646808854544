import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard4 from '../components/feature-card4'
import './support-us.css'

const SupportUs = (props) => {
  const burgerPagePrefix = 'support-us'

  useEffect(() => {
    const burgerBtn = document.getElementsByClassName(burgerPagePrefix + '-burger-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (burgerBtn && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.add("teleport-show");
      }
      burgerBtn.addEventListener("click", handleClick);
      return () => {
        burgerBtn.removeEventListener('click', handleClick)
      }
    }
  }, []);

  useEffect(() => {
    const mobileCloseMenu = document.getElementsByClassName(burgerPagePrefix + '-close-menu')[0];
    const mobileMenu = document.getElementsByClassName(burgerPagePrefix + '-mobile-menu')[0];

    if (mobileCloseMenu && mobileMenu) {
      const handleClick = () => {
          mobileMenu.classList.remove("teleport-show");
      }
      mobileCloseMenu.addEventListener("click", handleClick);
      return () => {
        mobileCloseMenu.removeEventListener('click', handleClick)
      }
    }
  }, []);

  return (
    <div className="support-us-container">
      <Helmet>
        <title>Support-Us - Nexus at Cornell</title>
        <meta
          name="description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
        <meta property="og:title" content="Support-Us - Nexus at Cornell" />
        <meta
          property="og:description"
          content="Student team building an autonomous robot to help filter microplastics on the beaches."
        />
      </Helmet>
      <div data-role="Header" className="support-us-navbar-container">
        <div className="support-us-navbar">
          <div className="support-us-left-side">
            <img
              alt="image"
              src="/playground_assets/logo1-1500h.png"
              className="support-us-image"
            />
            <div className="support-us-burger-menu">
              <svg viewBox="0 0 1024 1024" className="support-us-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className="support-us-links-container">
              <Link to="/" className="support-us-link Anchor">
                Home
              </Link>
              <Link to="/about-us" className="support-us-link01 Anchor">
                About Us
              </Link>
              <Link to="/team" className="support-us-link02 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="support-us-link03 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="support-us-link04 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
          <div className="support-us-right-side">
            <Link to="/support-us" className="support-us-cta-btn Anchor button">
              Support Us
            </Link>
          </div>
          <div data-role="MobileMenu" className="support-us-mobile-menu">
            <div className="support-us-container1">
              <img
                alt="image"
                src="/playground_assets/logo1-1500h.png"
                className="support-us-image1"
              />
              <div
                data-role="CloseMobileMenu"
                className="support-us-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="support-us-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className="support-us-links-container1">
              <Link to="/" className="support-us-link05 Anchor">
                Home
              </Link>
              <Link to="/about-us" className="support-us-link06 Anchor">
                About Us
              </Link>
              <Link to="/team" className="support-us-link07 Anchor">
                Team
              </Link>
              <Link to="/subteams" className="support-us-link08 Anchor">
                Subteams
              </Link>
              <Link to="/recruitment" className="support-us-link09 Anchor">
                Recruitment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="support-us-features">
        <h1 className="support-us-text">
          <span className="support-us-text01">Support Us</span>
          <span className="support-us-text02"></span>
        </h1>
        <div className="support-us-separator"></div>
        <div className="support-us-container2">
          <div className="support-us-container3">
            <FeatureCard4
              title="Funding"
              new_prop="Funding"
              description="Funding is essential. We have lots of talented engineers and business people, but are in need of more financial resources to help us finish our build and grow our presence. "
              rootClassName="feature-card4-root-class-name1"
            ></FeatureCard4>
            <FeatureCard4
              title="Connections"
              new_prop="Networking"
              description="We are looking to expand our network and gain valuable connections with leaders, sustainability organizations, engineering teams, etc., who could be valuable resources in helping to reach our future goals.  "
              rootClassName="feature-card4-root-class-name4"
            ></FeatureCard4>
            <FeatureCard4
              title="Mentorship"
              new_prop="Mentorship"
              description="We are seeking advice on the technical, business, and sustainability end of our project. We want to gain valuable insight from professors and industry professionals on how we can further make an impact."
              rootClassName="feature-card4-root-class-name3"
            ></FeatureCard4>
            <FeatureCard4
              title="Publicity"
              new_prop="Social Change"
              description="We are looking to get the word out about the amazing work we are doing at Nexus! Reach out if you or a community you are a part of would be interested in learning more about us."
              rootClassName="feature-card4-root-class-name2"
            ></FeatureCard4>
          </div>
          <img
            alt="image"
            src="/playground_assets/turtle%20render2-2%20%5B1%5D-500h.jpg"
            className="support-us-image2"
          />
        </div>
      </div>
      <div id="process" className="support-us-services">
        <div className="support-us-heading-container">
          <h1 className="support-us-text03 Section-Heading">How Can I Help?</h1>
        </div>
        <div className="support-us-cards-container">
          <div className="support-us-service-card">
            <h3 className="support-us-text04 Card-Heading">Sponsorships</h3>
            <span className="support-us-text05 Card-Text">
              Forming partnerships and receiving sponsors is a large part of how
              we receive funding and gain valuable partners.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="https://drive.google.com/file/d/1G1Gyx3jsO0Q8uyapPB8qlHyyrt-MZLvg/view?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              className="support-us-link10 Anchor"
            >
              Learn more
            </a>
          </div>
          <div className="support-us-service-card1">
            <h3 className="support-us-text06 Card-Heading">
              Donate To Our Team
            </h3>
            <span className="support-us-text07">
              <span className="Card-Text">
                Every dollar counts towards
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>helping</span>
              <span className="Card-Text">
                {' '}
                to make a difference. Click the link below to go to our donation
                page.
              </span>
            </span>
            <a
              href="https://securelb.imodules.com/s/1717/giving/interior.aspx?sid=1717&amp;gid=2&amp;pgid=16421&amp;cid=27217&amp;dids=5455&amp;sort=1&amp;bledit=1"
              target="_blank"
              rel="noreferrer noopener"
              className="support-us-link11"
            >
              Learn more
            </a>
          </div>
          <div className="support-us-service-card2">
            <h3 className="support-us-text11 Card-Heading">
              Cornell Giving Day
            </h3>
            <span className="support-us-text12">
              Thank you so much for your support from Giving Day! As a result, we raised over
              $7K for our team!
            </span>
            <a
              href="https://securelb.imodules.com/s/1717/giving/interior.aspx?sid=1717&gid=2&pgid=16421&cid=27217&dids=5455&sort=1&bledit=1"
              target="_blank"
              rel="noreferrer noopener"
              className="support-us-link12 Anchor"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <div className="support-us-section-separator"></div>
      <div className="support-us-section-separator1"></div>
      <div className="support-us-section-separator2"></div>
      <div className="support-us-section-separator3"></div>
      <div className="support-us-footer-container">
        <div className="support-us-footer">
          <div className="support-us-social-links">
            <a
              href="mailto:cornellnexus@gmail.com?subject="
              className="support-us-link13"
            >
              <svg viewBox="0 0 1024 1024" className="support-us-icon04">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornellnexus/"
              target="_blank"
              rel="noreferrer noopener"
              className="support-us-link15"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="support-us-icon08"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
          </div>
          <div className="disclaimer-footer">
            <span id="footer-center" className="support-us-heading">Nexus at Cornell, 2024</span>
            <span className="disclaimer">This organization is a registered student organization of Cornell University</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportUs
