import React from 'react'

import PropTypes from 'prop-types'

import './testimonial-card3.css'

const TestimonialCard3 = (props) => {
  // processString bolds string in props.name between <b> </b>

  const processString = (input) => {
    let result = [];
    let lastIndex = 0;

    const regexCombined = /<b>(.*?)<\/b>|<a href='(.*?)'>(<b>)?(.*?)(<\/b>)?<\/a>/g;

    let match;

    while ((match = regexCombined.exec(input))) {
      result.push(input.substring(lastIndex, match.index));

      if (match[1]) {
        // Bold match (not inside a link)
        result.push(<strong key={'bold-text-' + match.index}>{match[1]}</strong>);
      } else if (match[2] && match[4]) {
        // Link match
        if (match[3]) {
          // Link with bold text
          result.push(<a key={'link-' + match.index} href={match[2]} style={{ color: '#000080' }} target="_blank"> <strong>{match[4]}</strong></ a>);
        } else {
          // Regular link
          result.push(<a key={'link-' + match.index} href={match[2]} style={{ color: '#000080' }} target="_blank"> {match[4]}</ a>);
        }
      }

      lastIndex = regexCombined.lastIndex;
    }

    result.push(input.substring(lastIndex));
    return result;
  };



  // renderedLines creates new line after \n in props.name string
  const lines = props.name.split('\\n');
  const renderedLines = lines.map((line, index) => (
    <React.Fragment key={index}>
      {processString(line)}
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ));
  return (
    <header className={`testimonial-card3-testimonial-card ${props.rootClassName}`}>
      <div className="testimonial-card3-testimonial">
        <span className="testimonial-card3-text">{props.quote}</span>
        <span className="testimonial-card3-text1">{renderedLines}</span>
      </div>
    </header>
  );
}



TestimonialCard3.defaultProps = {
  name: 'John Doe',
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.  Nam pellentesque nulla leo, sagittis vehicula sem commodo nec.',
  rootClassName: '',
}

TestimonialCard3.propTypes = {
  name: PropTypes.string,
  quote: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default TestimonialCard3
